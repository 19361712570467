<template>
  <el-dialog
    :visible.sync="visible"
    class="yt-dialog"
    top="30px"
    :fullscreen="fullVisible"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <div slot="title">
      <el-menu v-if="mode" class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="0">手动添加</el-menu-item>
        <el-menu-item index="1">批量添加</el-menu-item>
      </el-menu>
      <template v-else><span class="el-dialog__title">题目编辑</span></template>
      <el-button type="primary" size="small" @click="fullVisible = !fullVisible">
        <div>
          <YTIcon :href="fullVisible ? '#icon-quanpingshouqi1' : '#icon-quanping'"></YTIcon>
          <p>全屏编辑</p>
        </div>
      </el-button>
    </div>
    <el-form ref="form" label-width="96px" :model="questionForm" :rules="ruleValidate">
      <el-form-item label="选择题库" prop="questionBankId">
        <el-select v-model="questionForm.questionBankId" placeholder="请选择题库" style="width: 100%">
          <template v-for="item in questionBankList">
            <el-option :key="item.questionBankId" :label="item.name" :value="item.questionBankId"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <template v-if="activeIndex === '0'">
        <el-row>
          <el-col :span="14">
            <el-form-item label="题目类型" prop="questionType">
              <el-radio-group size="small" v-model="questionForm.questionType" @change="changeType">
                <el-radio-button v-for="(item, index) in this.ytConstant.questionType.getMenu()" :label="item.value" :disabled="!mode" :key="index">
                  {{ item.label }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="题目难度" prop="difficulty">
              <el-radio-group v-model="questionForm.difficulty">
                <el-radio :label="item.value" v-for="(item, index) in this.ytConstant.difficultType.getMenu()" :key="index">{{
                  item.label
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="题干" prop="stem">
          <RichEditor
            ref="stemInput"
            v-if="stemVisible"
            :defaultText="indicators[questionForm.questionType].stemPlaceHolder"
            @input="handleStemInput"
            @validate="validateItem('stem')"
          ></RichEditor>
        </el-form-item>
        <component
          ref="indicator"
          :is="indicators[questionForm.questionType].component"
          :form="questionForm"
          :errorList="errorList"
          @addOption="addOption"
          @addTestcase="addTestcase"
          @deleteOption="deleteOption"
          @deleteTestcase="deleteTestcase"
          @handleCheckedOption="handleCheckedOption"
          @handleOptionInput="handleOptionInput"
          @handleAnswerInput="handleAnswerInput"
          @handleTemplateInput="handleTemplateInput"
          @handleTestcaseInput="handleTestcaseInput"
          @validate="validateItem"
        ></component>
        <el-form-item label="解析">
          <RichEditor v-if="stemVisible" ref="analysisEditor" :defaultText="'请输入解析'" @input="handleAnalysisInput"></RichEditor>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="标签:">
              <div class="tk-item tk-select-item">
                <template v-for="(tag, tIndex) in questionForm.tags">
                  <div :key="tIndex">
                    <el-tooltip effect="dark" :disabled="tag.label.length < 4" :content="tag.label" placement="top" :open-delay="1000">
                      <div class="tk-point" :key="tIndex" v-if="tIndex < 5">
                        {{ tag.label }}
                      </div>
                    </el-tooltip>
                  </div>
                </template>
                <el-popover placement="top" width="202" trigger="hover" title="题目标签" v-if="questionForm.tags.length > 5">
                  <div class="tk-select-item tab-list" style="margin-bottom: 5px">
                    <template v-for="(tagPop, tPopIndex) in questionForm.tags">
                      <div :key="tPopIndex">
                        <el-tooltip effect="dark" :disabled="tagPop.label.length < 4" :content="tagPop.label" placement="top" :open-delay="1000">
                          <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= 5" style="margin-bottom: 5px">
                            {{ tagPop.label }}
                          </div>
                        </el-tooltip>
                      </div>
                    </template>
                  </div>
                  <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
                </el-popover>
                <!--编辑题目标签-->
                <el-dropdown placement="bottom-start" @visible-change="handleTagDropdownChange">
                  <div><YTIcon :href="'#icon-xinzeng'"></YTIcon></div>
                  <el-dropdown-menu slot="dropdown">
                    <el-input
                      v-model="tagAddKeyword"
                      class="yt-search search"
                      placeholder="通过标签名称筛选"
                      @keydown.enter.native="toFilterNodeUpdate('tag-tree-add', tagAddKeyword)"
                    >
                      <i slot="suffix" class="el-input__icon el-icon-search" @click="toFilterNodeUpdate('tag-tree-add', tagAddKeyword)"></i>
                    </el-input>
                    <div class="list-container" style="height: calc(100vh - 500px);">
                      <el-tree :data="tagList" node-key="value" ref="tag-tree-add" :filter-node-method="filterNode" show-checkbox> </el-tree>
                    </div>
                    <div class="footer">
                      <el-button type="primary" @click="toUpdateTagsForQuestion">确定</el-button>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="知识点:" prop="knowledgePoints">
              <div class="tk-item tk-select-item">
                <template v-for="(knowledge, knowledgeIndex) in questionForm.knowledgePoints">
                  <div :key="knowledgeIndex">
                    <el-tooltip
                      effect="dark"
                      :disabled="knowledge.hasOwnProperty('name') && knowledge.name.length < 4"
                      :content="knowledge.hasOwnProperty('name') ? knowledge.name : '-'"
                      placement="top"
                      :open-delay="1000"
                    >
                      <div class="tk-point" :key="knowledgeIndex" v-if="knowledgeIndex < 5">
                        {{ knowledge.hasOwnProperty('name') ? knowledge.name : '-' }}
                      </div>
                    </el-tooltip>
                  </div>
                </template>
                <el-popover placement="top" width="202" trigger="hover" title="题目知识点" v-if="questionForm.knowledgePoints.length > 5">
                  <div class="tk-select-item tab-list" style="margin-bottom: 5px; max-height: calc(100vh / 2); overflow-y: auto">
                    <template v-for="(knowledgePop, knowledgePopIndex) in questionForm.knowledgePoints">
                      <div :key="knowledgePopIndex">
                        <el-tooltip
                          effect="dark"
                          :disabled="knowledgePop.hasOwnProperty('name') && knowledgePop.name.length < 4"
                          :content="knowledgePop.hasOwnProperty('name') ? knowledgePop.name : '-'"
                          placement="top"
                          :open-delay="1000"
                        >
                          <div class="tk-point" style="margin-bottom: 5px" :key="knowledgePopIndex" v-if="knowledgePopIndex >= 5">
                            {{ knowledgePop.hasOwnProperty('name') ? knowledgePop.name : '-' }}
                          </div>
                        </el-tooltip>
                      </div>
                    </template>
                  </div>
                  <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
                </el-popover>
                <!--编辑题目知识点-->
                <el-dropdown placement="bottom-start" @visible-change="handleDropdownChange">
                  <div><YTIcon :href="'#icon-xinzeng'"></YTIcon></div>
                  <el-dropdown-menu slot="dropdown">
                    <el-input
                      v-model="knowledgePointAddKeyword"
                      class="yt-search search"
                      placeholder="通过知识点名称筛选"
                      @keydown.enter.native="toFilterNodeUpdate('knowledge-tree-add', knowledgePointAddKeyword)"
                    >
                      <i
                        slot="suffix"
                        class="el-input__icon el-icon-search"
                        @click="toFilterNodeUpdate('knowledge-tree-add', knowledgePointAddKeyword)"
                      ></i>
                    </el-input>
                    <div class="list-container" style="height: calc(100vh - 500px);">
                      <el-tree
                        :data="knowledgePointList"
                        :props="knowledgePointProps"
                        node-key="id"
                        ref="knowledge-tree-add"
                        :filter-node-method="filterNode"
                        show-checkbox
                        check-strictly
                      >
                      </el-tree>
                    </div>
                    <div class="footer">
                      <el-button @click="toClearKnowledgePointsForQuestion">清空</el-button>
                      <el-button type="primary" @click="toUpdateKnowledgePointsForQuestion">确定</el-button>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="预计完成时间">
          <el-input-number class="expectedTime" v-model="questionForm.expectedTime" controls-position="right" :min="1" :max="60"></el-input-number>
          <span style="margin-left: 8px;">分钟</span>
        </el-form-item>
        <el-form-item label="审核">
          <el-switch v-model="isChecked"></el-switch>
        </el-form-item>
      </template>
      <BulkImport v-else ref="bulkImport" :downloadVisible="downloadVisible"></BulkImport>
    </el-form>
    <div slot="footer" class="footer" :style="{ justifyContent: activeIndex === '0' ? 'center' : 'space-between' }">
      <div v-if="activeIndex === '1'" style="display: flex">
        <el-upload action="" :show-file-list="false" accept=".xls,.xlsx,.doc,.docx" :http-request="uploadImportFile" :on-change="handleFileChange">
          <el-button class="button" type="primary">
            <div>
              <YTIcon :href="'#icon-daochu'"></YTIcon>
              <p>上传文件</p>
            </div>
          </el-button>
        </el-upload>
        <el-button class="button" @click="downloadVisible = !downloadVisible">
          <div>
            <YTIcon :href="'#icon-daoru5'"></YTIcon>
            <p>下载模板</p>
          </div>
        </el-button>
      </div>
      <div>
        <el-button class="button" @click="visible = false">取消</el-button>
        <el-button class="button" :loading="loading" @click="submit(1)">保存草稿</el-button>
        <el-button class="button" style="margin-right: 0" type="primary" :loading="loading" @click="submit(2)">发布</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import RichEditor from '@components/common/RichEditor'
import SingleChoice from '@components/common/questions/form/SingleChoice'
import MultiChoice from '@components/common/questions/form/MultiChoice'
import TrueOrFalse from '@components/common/questions/form/TrueOrFalse'
import Answer from '@components/common/questions/form/Answer'
import FillInBlank from '@components/common/questions/form/FillInBlank'
import Code from '@components/common/questions/form/Code'
import BulkImport from '@components/common/questions/BulkImport'
import questionBankApi from '@api/questionBank'
import fileApi from '@api/file'
import questionApi from '@api/question'
export default {
  name: 'QuestionCUDialog',
  components: {
    YTIcon,
    RichEditor,
    SingleChoice,
    MultiChoice,
    TrueOrFalse,
    Answer,
    FillInBlank,
    Code,
    BulkImport
  },
  props: ['tagList', 'knowledgePointList'],
  data() {
    const validateStem = (rule, value, callback) => {
      if (this.questionForm.stem === '') {
        this.$refs.stemInput.changeColor('#FF5050')
        callback(new Error('请输入题干'))
      } else {
        callback()
      }
    }
    const validateOptions = (rule, value, callback) => {
      if (
        this.questionForm.options < 2 &&
        this.questionForm.questionType !== this.ytConstant.questionType.SUBJECTIVE &&
        this.questionForm.questionType !== this.ytConstant.questionType.TRUE_OR_FALSE
      ) {
        //选择题选项少于1条不判断
        callback()
      } else {
        //判断选项是否为空
        let emptyIndex = this.questionForm.options.findIndex(item => {
          return item.content === ''
        })
        if (emptyIndex !== -1) {
          this.setOptionError(emptyIndex)
          callback(new Error(this.questionForm.questionType === this.ytConstant.questionType.FILL_IN_BLANK ? '请填写填空答案' : '请填写选项内容'))
        } else {
          //判断是否有重复选项
          let duplicate = this.questionForm.options.findIndex((item, index) => {
            return (
              this.questionForm.options.findIndex((item1, index1) => {
                return item.content === item1.content
              }) !== index
            )
          })
          if (this.questionForm.questionType !== this.ytConstant.questionType.FILL_IN_BLANK && duplicate !== -1) {
            this.setOptionError(duplicate)
            callback(new Error('选项内容重复'))
          } else {
            //判断是否选择或填写答案
            let answer = this.questionForm.options.findIndex(item => {
              return item.correct
            })
            if (
              this.questionForm.questionType === this.ytConstant.questionType.SINGLE_CHOICE ||
              this.questionForm.questionType === this.ytConstant.questionType.MULTIPLE_CHOICE
            ) {
              if (answer === -1) {
                callback(new Error('请选择正确答案'))
              } else {
                callback()
              }
            } else {
              if (this.questionForm.questionType !== this.ytConstant.questionType.FILL_IN_BLANK && this.questionForm.answer === '') {
                if (this.questionForm.questionType === this.ytConstant.questionType.SUBJECTIVE) {
                  this.setOptionError()
                } else {
                  this.$set(this.errorList, 'answer', true)
                }
                callback(
                  new Error(this.questionForm.questionType === this.ytConstant.questionType.TRUE_OR_FALSE ? '请选择正确答案' : '请填写正确答案')
                )
              } else {
                this.$set(this.errorList, 'answer', false)
                callback()
              }
            }
          }
        }
      }
    }
    const validateOptionsLength = (rule, value, callback) => {
      if (this.questionForm.questionType === this.ytConstant.questionType.FILL_IN_BLANK) {
        let emptyReg = /__[1-9]__/gi
        let optionLength = this.questionForm.stem.match(emptyReg)
        if (optionLength === null && this.questionForm.stem !== '') {
          callback(new Error('题干中至少要有1个填空'))
        } else if (this.questionForm.options.length !== optionLength.length) {
          callback(new Error('填空个数与题干中的个数不符'))
        } else {
          callback()
        }
      } else {
        if (this.questionForm.options.length < 2) {
          callback(new Error('选择题至少要有2个选项'))
        } else {
          callback()
        }
      }
    }
    const validateTestcase = (rule, value, callback) => {
      let emptyIndex = this.questionForm.testcase.findIndex(item => {
        return item.input === '' || item.output === ''
      })
      if (emptyIndex !== -1) {
        this.setOptionError(emptyIndex)
        callback(new Error('请填写测试用例'))
      } else {
        callback()
      }
    }
    return {
      indicators: [
        { component: TrueOrFalse, stemPlaceHolder: '请输入正文' },
        { component: SingleChoice, stemPlaceHolder: '请输入正文' },
        { component: MultiChoice, stemPlaceHolder: '请输入正文' },
        { component: FillInBlank, stemPlaceHolder: '空格处使用两个下划线和一个序号类似__1__的格式标注' },
        { component: Answer, stemPlaceHolder: '请输入正文' },
        { component: Code, stemPlaceHolder: '请输入正文' }
      ],
      ruleValidate: {
        questionType: { required: true, trigger: 'blur' },
        difficulty: { required: true, message: '请选择题目难度', trigger: 'blur' },
        expectedTime: { required: true, type: 'number', message: '请输入时间', trigger: 'blur' },
        stem: {
          required: true,
          validator: validateStem
        },
        questionBankId: {
          required: true,
          message: '请选择题库',
          trigger: 'change'
        },
        answer: {
          validator: validateOptions
        },
        options: {
          validator: validateOptionsLength
        },
        testcase: {
          validator: validateTestcase
        }
      },
      visible: false,
      mode: false,
      loading: false,
      fullVisible: true,
      activeIndex: '0',
      questionForm: {
        questionBankId: '', //题库
        questionType: 0, //题目类型
        knowledgePointIds: [], //知识点id
        knowledgePoints: [], //知识点
        difficulty: this.ytConstant.difficultType.SIMPLE_MAX, //题目难度
        expectedTime: 1, //预期时间
        stem: '', //题干
        answer: '', //答案
        analysis: '', //解析
        options: [],
        tags: [], //标签
        testcase: [], //测试用例
        codeQuestionTemplates: [] //模板
      },
      isChecked: false,
      stemVisible: true,
      downloadVisible: false,
      questionBankList: [],
      uploadFile: null,
      errorList: {},
      defaultTemplate: [],
      trueEnums: ['对', '是', '正确', 'T'],
      knowledgePointProps: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      tagAddKeyword: '', //标签筛选关键词
      knowledgePointAddKeyword: '' //知识点筛选关键词
    }
  },
  methods: {
    changeType(value) {
      this.questionForm = {
        questionBankId: this.questionForm.questionBankId, //题库
        questionType: value, //题目类型
        knowledgePointIds: [], //知识点id
        knowledgePoints: [], //知识点
        difficulty: this.ytConstant.difficultType.SIMPLE_MAX, //题目难度
        expectedTime: 1, //预期时间
        stem: '', //题干
        answer: '', //答案
        analysis: '', //解析
        options: [],
        tags: [], //标签
        testcase: [], //测试用例
        codeQuestionTemplates: this.defaultTemplate //模板
      }
      this.isChecked = false
      //重置题干富文本提示文字
      this.stemVisible = !this.stemVisible
      this.$nextTick(() => {
        this.stemVisible = !this.stemVisible
      })
    },
    open(mode, data) {
      this.visible = true
      this.mode = mode
      this.getQuestionBankList()
      this.getDefaultCodeTemplate()
      if (!mode) {
        this.initData(data)
      }
    },
    initData(data) {
      this.questionForm = { ...this.questionForm, ...data, tags: [], knowledgePoints: [], options: [], codeQuestionTemplates: [] }
      if (data.hasOwnProperty('options')) {
        this.questionForm.options = data.options.map(item => {
          return item
        })
      }
      if (data.hasOwnProperty('tags')) {
        this.questionForm.tags = data.tags.map(item => {
          return {
            label: item.name,
            value: item.tagId
          }
        })
      }
      if (data.hasOwnProperty('knowledgePoints')) {
        this.questionForm.knowledgePoints = data.knowledgePoints.map(item => {
          return {
            name: item.knowledgeName,
            id: item.knowledgeId
          }
        })
      }
      if (data.hasOwnProperty('codeQuestionTemplates')) {
        this.questionForm.codeQuestionTemplates = data.codeQuestionTemplates.map(item => {
          return {
            template: item.template,
            codeType: item.codeType
          }
        })
      }
      if (data.questionType === this.ytConstant.questionType.FILL_IN_BLANK) {
        this.questionForm.options = data.blanks.map((item, index) => {
          return {
            content: item.blankAnswer.join(';'),
            index: index
          }
        })
      }
      this.$nextTick(() => {
        this.$refs.stemInput.modifyEditor(this.questionForm.stem)
        this.$refs.analysisEditor.modifyEditor(this.questionForm.analysis)
        this.isChecked = this.questionForm.status === 0
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.mode = false
      this.activeIndex = '0'
      this.isChecked = false
      this.fullVisible = true
      this.$refs.form.resetFields()
      this.questionForm = {
        questionBankId: '', //题库
        questionType: 0, //题目类型
        knowledgePointIds: [], //知识点id
        knowledgePoints: [], //知识点
        difficulty: this.ytConstant.difficultType.SIMPLE_MAX, //题目难度
        expectedTime: 1, //预期时间
        stem: '', //题干
        answer: '', //答案
        analysis: '', //解析
        options: [],
        tags: [], //标签
        testcase: [], //测试用例
        codeQuestionTemplates: [] //模板
      }
      this.errorList = []
      this.loading = false
      //重置题干富文本提示文字
      this.stemVisible = !this.stemVisible
      this.$nextTick(() => {
        this.stemVisible = !this.stemVisible
      })
    },
    submit(status) {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.mode) {
            if (this.activeIndex === '0') {
              this.addQuestion(status)
            } else {
              this.importQuestion(status)
            }
          } else {
            this.updateQuestion(status)
          }
        }
      })
    },
    addQuestion(status) {
      let payload = {
        ...this.questionForm,
        status: status === 1 ? status : this.isChecked ? 0 : status
      }
      payload.knowledgePointIds = payload.knowledgePoints.map(item => {
        return item.id
      })
      payload.tags = payload.tags.map(item => {
        return { name: item.label }
      })
      if (payload.questionType === this.ytConstant.questionType.FILL_IN_BLANK) {
        payload.blanks = payload.options.map((item, index) => {
          return {
            blankId: `__${index + 1}__`,
            blankAnswer: item.content.split(';')
          }
        })
      }
      questionApi
        .addQuestion(payload)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('添加成功')
            this.$emit('refreshQuestions')
            this.visible = false
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    updateQuestion(status) {
      let payload = {
        ...this.questionForm,
        status: status === 1 ? status : this.isChecked ? 0 : status,
        name: ''
      }
      payload.knowledgePointIds = payload.knowledgePoints.map(item => {
        return item.id
      })
      payload.tags = payload.tags.map(item => {
        return { name: item.label }
      })
      if (payload.questionType === this.ytConstant.questionType.FILL_IN_BLANK) {
        payload.blanks = payload.options.map((item, index) => {
          return {
            blankId: `__${index + 1}__`,
            blankAnswer: item.content.split(';')
          }
        })
      }
      questionApi
        .modifyQuestion(payload.id, payload)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('编辑成功')
            this.visible = false
            this.$emit('refresh', this.questionForm, this.questionForm.difficulty, this.questionForm.answer, this.questionForm.options)
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    importQuestion(status) {
      let hasError = this.$refs.bulkImport.questionList.some(item => {
        return item.hasError
      })
      if (hasError) {
        this.loading = false
        return
      }
      let payload = this.$refs.bulkImport.questionList.map(item => {
        let blanks = []
        let answer = item.answer
        let testcase = []
        let tags = []
        if (item.type === this.ytConstant.questionType.FILL_IN_BLANK) {
          blanks = item.answer.split('|').map((item, index) => {
            return {
              blankId: `__${index + 1}__`,
              blankAnswer: item.split(';')
            }
          })
        }
        if (item.type === this.ytConstant.questionType.TRUE_OR_FALSE) {
          item.options.forEach(item => {
            if (item.correct) {
              answer = this.trueEnums.includes(item.content)
            }
          })
        }
        if (item.type === this.ytConstant.questionType.CODE) {
          testcase = item.testcase.split('|').map(item => {
            let arr = item.split(';')
            return {
              input: arr[0],
              output: arr[1]
            }
          })
        }
        if (item.hasOwnProperty('kwordValue')) {
          tags = item.kwordValue.map(item => {
            return { name: item }
          })
        }
        return {
          questionType: item.type,
          stem: item.title_eles.join(''),
          answer: answer,
          analysis: item.analysis,
          difficulty: item.difficultyValue,
          expectedTime: item.expectedTime.split('分钟')[0],
          options: item.options,
          blanks: blanks,
          tags: tags,
          testcase: testcase,
          knowledgePointIds: [],
          fileUrl: '',
          codeQuestionTemplates: [],
          status: status
        }
      })
      questionApi
        .addBatchQuestions(payload, this.questionForm.questionBankId)
        .then(res => {
          if (res.res === true) {
            this.$message.success('添加成功')
            this.$emit('refreshQuestions')
            this.visible = false
          } else {
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
        })
    },
    selectMenu(index) {
      this.activeIndex = index
      if (index === '1') {
        this.$nextTick(() => {
          this.$refs.bulkImport.initEditor()
        })
      }
    },
    getDefaultCodeTemplate() {
      questionApi.getDefaultTemplate().then(res => {
        this.defaultTemplate = res.res.map(item => {
          return {
            codeType: item.codeType,
            template: item.defaultTemplate
          }
        })
      })
    },
    getQuestionBankList() {
      questionBankApi.searchSimpleInfo().then(res => {
        this.questionBankList = res.res
      })
    },
    addOption() {
      //单选多选填空添加选项或空格
      this.questionForm.options.push({
        content: '',
        correct: false,
        index: this.questionForm.options.length === 0 ? 0 : this.questionForm.options[this.questionForm.options.length - 1].index + 1
      })
      this.validateItem('options')
    },
    addTestcase() {
      this.questionForm.testcase.push({
        input: '',
        output: '',
        index: this.questionForm.testcase.length === 0 ? 0 : this.questionForm.testcase[this.questionForm.testcase.length - 1].index + 1
      })
    },
    deleteOption(index) {
      //单选多选填空删除选项或空格
      this.questionForm.options.splice(index, 1)
      this.validateItem('options')
    },
    deleteTestcase(index) {
      //代码题删除测试用例
      this.questionForm.testcase.splice(index, 1)
    },
    handleCheckedOption(index) {
      this.questionForm.options.forEach((item, i) => {
        if (item.correct && i !== index) {
          item.correct = false
        }
      })
    },
    handleStemInput(text) {
      //题干输入
      this.questionForm.stem = text
    },
    handleOptionInput(text, index) {
      //选项, 填空输入
      this.questionForm.options[index].content = text
    },
    handleAnalysisInput(text) {
      //解析输入
      this.questionForm.analysis = text
    },
    handleAnswerInput(text) {
      //答案输入
      this.questionForm.answer = text
    },
    handleTemplateInput(value, type) {
      //代码模板
      this.questionForm.codeQuestionTemplates.forEach(item => {
        if (item.codeType === type) {
          item.template = value
        }
      })
    },
    handleTestcaseInput(value, index, type) {
      //测试用例
      this.questionForm.testcase[index][type] = value
      this.validateItem('testcase')
    },
    validateItem(prop) {
      this.$refs.form.validateField(prop, () => {})
    },
    setOptionError(index) {
      this.$refs.indicator.setOptionError(index)
    },
    uploadImportFile() {
      //上传导入题目
      let nameArr = this.uploadFile.name.split('.')
      let type = nameArr.slice(-1)[0]
      let payload
      if (type === 'xls' || type === 'xlsx') {
        payload = 0
      } else if (type === 'doc' || type === 'docx') {
        payload = 1
      } else {
        this.$message.warning('请上传excel或word格式的文件')
      }
      let formData = new FormData()
      formData.append('file', this.uploadFile.raw, this.uploadFile.name)
      fileApi.importQuestion(payload, formData).then(res => {
        this.$refs.bulkImport.handleImportText(res.res)
      })
    },
    handleFileChange(file) {
      this.uploadFile = file
    },
    handleTagDropdownChange(visible) {
      if (!visible) {
        this.tagAddKeyword = ''
        this.$refs['tag-tree-add'].setCheckedKeys([])
        this.toFilterNodeUpdate('tag-tree-add', this.tagAddKeyword)
      } else {
        this.$refs['tag-tree-add'].setCheckedNodes(this.questionForm.tags)
      }
    },
    handleDropdownChange(visible) {
      if (!visible) {
        this.knowledgePointAddKeyword = ''
        this.$refs['knowledge-tree-add'].setCheckedKeys([])
        this.toFilterNodeUpdate('knowledge-tree-add', this.knowledgePointAddKeyword)
      } else {
        this.$refs['knowledge-tree-add'].setCheckedNodes(this.questionForm.knowledgePoints)
      }
    },
    toFilterNodeUpdate(ref, keyword) {
      //树过滤点击触发(新建题目)
      this.$refs[ref].filter(keyword)
      this.$refs[ref].setCheckedNodes(ref === 'knowledge-tree-add' ? this.questionForm.knowledgePointIds : this.questionForm.tags)
    },
    filterNode(value, data) {
      //树过滤
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    toUpdateTagsForQuestion() {
      this.questionForm.tags = this.$refs['tag-tree-add'].getCheckedNodes()
    },
    toUpdateKnowledgePointsForQuestion(question, index) {
      //变更题目知识点
      this.questionForm.knowledgePoints = this.$refs['knowledge-tree-add'].getCheckedNodes()
    },
    toClearKnowledgePointsForQuestion() {
      //清空题目知识点
      this.$refs['knowledge-tree-add'].setCheckedKeys([])
      this.toUpdateKnowledgePointsForQuestion()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/filterDropdownMenu';
.yt-dialog {
  ::v-deep .el-dialog {
    width: 1080px;
    border-radius: 8px !important;
    margin: 0 auto 30px;
  }
  ::v-deep .el-dialog__body {
    padding-left: 50px;
    padding-right: 51px;
    //max-height: calc(100vh - 225px);
    padding-bottom: 0;
  }
  ::v-deep .el-dialog__header {
    ul {
      border-radius: 8px;
    }
    .el-button {
      height: 38px;
      line-height: 38px;
      position: absolute;
      top: 12px;
      left: 50px;
    }
  }
  .footer {
    .flexStyle(flex);
    margin: 0 60px;
  }
  .button {
    margin-right: 20px;
    margin-left: 0;
    &:last-of-type {
      margin-right: 20px;
    }
  }
}
::v-deep .el-radio__input {
  height: 14px;
  &.is-checked {
    & + .el-radio__label {
      color: #448bff;
    }
    .el-radio__inner {
      background: #fff;
      border-color: #448bff;
      &::after {
        background-color: #448bff;
        width: 10px;
        height: 10px;
      }
    }
  }
}
::v-deep .el-radio-button {
  &.is-active {
    &.is-disabled {
      .el-radio-button__inner {
        color: #ffffff;
      }
    }
  }
}
::v-deep .el-radio.is-bordered.is-checked {
  border-color: #448bff;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #448bff;
}
.yt-menu {
  .flexStyle(flex);
  height: 62px;
  margin-left: 0 !important;
  .el-menu-item {
    line-height: 62px !important;
  }
}
.expectedTime {
  width: 150px;
  ::v-deep .el-input-number__decrease,
  ::v-deep .el-input-number__increase {
    background: none;
    i {
      font-size: 14px;
    }
  }
}
::v-deep .el-input-number .el-input__inner {
  text-align: left;
}
::v-deep .el-input__inner {
  border-color: #d9d9d9;
}

::v-deep .set-correct-choice {
  .flexStyle();
  width: 150px;
  height: 38px;
  padding: 0;
}
::v-deep .option-wrapper {
  .flexStyle(flex, space-between);
  &.choice {
    align-items: flex-start;
  }
}
.tk-item {
  .flexStyle(flex, flex-start, center);
}
.tk-point {
  max-width: 70px;
  height: 22px;
  line-height: 22px;
  border-color: #edf4ff;
  border-radius: 2px;
  color: #448bff;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
  }
  &:focus {
    border-color: #448bff;
  }
}
//题库列表悬停标签
.tab-list {
  display: flex;
  flex-wrap: wrap;
  .item {
    font-size: 10px;
    height: 18px;
    line-height: 18px;
    background: #edf4ff;
    border-radius: 10px;
    color: #448bff;
    padding: 0 8px;
    margin-right: auto;
    margin-bottom: 10px;
  }
}
</style>
