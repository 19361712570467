<template>
  <div class="import-wrapper">
    <div class="textarea-wrapper">
      <div class="header">
        <span>输入区</span>
        <div @click="openDropdown('standard')">
          规范
          <YTIcon :href="'#icon-open'" :class="{ active: dropdownVisible === 'standard' }"></YTIcon>
        </div>
        <div @click="openDropdown('example')">
          范例
          <YTIcon :href="'#icon-open'" :class="{ active: dropdownVisible === 'example' }"></YTIcon>
        </div>
      </div>
      <div id="inputEditor" ref="inputEditor" class="textarea"></div>
      <div id="inputEditorCopy" style="display: none"></div>
      <!--录入规范-->
      <div class="textarea standard" style="z-index: 99998" :style="{ display: dropdownVisible === 'standard' ? 'block' : 'none' }">
        <h4>录入规范</h4>
        <p>1.所有题型标号支持1.或1、两种格式；</p>
        <p>2.所有题型必须含有“答案：“字段，且不能为空；</p>
        <p>3.所有题型“解析：”字段非必需，没有可不填；</p>
        <p>4.暂不支持向输入区中插入图片；</p>
        <p>5.选择题最少支持三个选项A，B，C，最多支持10个选项A，B，C，D， E，F，G，H，l，J，且按照顺序使用；</p>
        <p>6.选择题A-这些选号与内容之间要用、或.分开；</p>
        <p>7.选择题答案中请勿加分隔符或者空格；</p>
        <p>8.判断题答案支持设置：“对或错”、“正确或错误”、“T或F”、“是或否”；</p>
        <p>9.填空题仅支持题目中出现括号；填空题目里的多个填空答案要用|分割， 单个答案不用添加；</p>
      </div>
      <!--范例-->
      <div class="textarea standard example" style="z-index: 99998" :style="{ display: dropdownVisible === 'example' ? 'block' : 'none' }">
        <template v-for="(item, index) in questionTemplate">
          <div :key="index">
            <p>{{ index + 1 }}. [{{ item.questionTypeName }}]{{ item.stem }}</p>
            <template v-if="item.questionType === 0">
              <p>A. 对</p>
              <p>B. 错</p>
            </template>
            <template v-if="item.hasOwnProperty('options')">
              <p v-for="(o, index) in item.options" :key="index">
                {{ String.fromCharCode(index + 65) + '. ' + o }}
              </p>
            </template>
            <template v-if="item.hasOwnProperty('testcase')">
              <p>[测试用例] {{ item.testcase }}</p>
            </template>
            <p>[答案] {{ item.answer }}</p>
            <p>[解析] {{ item.analysis }}</p>
            <p>[难度] {{ item.difficulty }}</p>
            <p>[预期时间] {{ item.expectedTime }}</p>
          </div>
        </template>
      </div>
    </div>
    <div class="preview" @click="formatText">预览</div>
    <div class="textarea-wrapper">
      <div class="header">
        <span>检查区[共: {{ questionList.length }}题]</span>
      </div>
      <div class="textarea output">
        <div style="height: 100%;overflow-y: auto">
          <template v-for="(question, index) in questionList">
            <div class="question-item" :class="{ error: question.hasError }" :key="index">
              <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px">
                <div>
                  {{ index + 1 }}.
                  <span class="question-type">{{ ytConstant.questionType.getLabel(question.type) }}</span>
                  <span class="question-type" style="background-color: #08dd93">{{
                    ytConstant.difficultType.getLabel(question.difficultyValue)
                  }}</span>
                </div>
                <el-tooltip v-if="question.hasError" effect="dark" :content="question.errors[Object.keys(question.errors)[0]]" placement="top-start">
                  <div class="question-type" style="cursor: pointer; width: auto; background-color: #FF5050">错误提示</div>
                </el-tooltip>
              </div>
              <div style="margin-bottom: 10px">
                <template v-for="(item, index) in question['title_eles']">
                  <div :key="index" v-html="item"></div>
                </template>
              </div>
              <template v-for="(item, index) in question.options">
                <p :key="index">
                  <el-radio :disabled="!item.correct" v-model="item.index" :label="item.correct ? item.index : -1">
                    {{ $getChoiceIndex(item.index) }}.
                    {{ question.optionValues[index] }}
                  </el-radio>
                </p>
              </template>
              <template v-if="question.type === ytConstant.questionType.CODE">
                <p>[测试用例] {{ question.testcase }}</p>
              </template>
              <p>[答案] {{ question.answer }}</p>
              <p v-if="question.hasOwnProperty('kwordValue')">[标签] {{ question.kwordValue.toString() }}</p>
              <p>[解析] {{ question.analysis }}</p>
              <p>[预期时间] {{ question.expectedTime }}</p>
            </div>
          </template>
        </div>
        <template></template>
      </div>
    </div>
    <!--下载模板-->
    <div class="download" :class="{ active: downloadVisible }">
      <div class="item" style="margin-right: 40px">
        <div class="header" style="background-color: #08dd93">
          <span>Excel导入</span>
          <div @click="downloadTemplate(0)">下载模板</div>
        </div>
        <div class="body">
          <h4>上传要求</h4>
          <p>1.支持单选、多选、判断题、填空题、简答题、代码题 批量导入；</p>
          <p>2.下载平台提供的文件模板；</p>
          <p>3.文件支持上传：xls、xlsx格式；</p>
          <p>4.上传题目不支持音频，不支持选项中添加图片，如有需求，请导入题目后 在"题库管理"中进行二次编辑；</p>
          <p>5.上传过程中如有疑问请及时联系客服人员。</p>
        </div>
      </div>
      <div class="item">
        <div class="header" style="background-color: #3d7de5">
          <span>Word导入</span>
          <div @click="downloadTemplate(1)">下载模板</div>
        </div>
        <div class="body">
          <h4>上传要求</h4>
          <p>1.支持单选、多选、判断题、填空题、简答题、代码题 批量导入；</p>
          <p>2.下载平台提供的文件模板；</p>
          <p>3.文件支持上传：docx格式；</p>
          <p>4.上传题目不支持音频，不支持选项中添加图片，如有需求，请导入题目后 在"题库管理"中进行二次编辑；</p>
          <p>5.上传过程中如有疑问请及时联系客服人员。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import E from 'wangeditor'
import YTIcon from '@components/common/YTIcon'
import { dialectBlock } from '@util/parser'
import { Base64 } from 'js-base64'
import fileApi from '@api/file'
export default {
  name: 'BulkImport',
  components: { YTIcon },
  props: ['downloadVisible'],
  data() {
    return {
      dropdownVisible: '',
      inputEditor: null,
      inputEditorCopy: null,
      outputCount: 0, //检查区题数
      inputTextLine: [],
      inputTextLineCopy: [],
      titleImgReg: /\|\|img(\d+)\|\|/g, //图片正则
      stemDataLength: '9999', //题目长度
      answerAnalysisLength: '1000', //答案解析长度
      chooseLengthMax: '200', //选项长度
      tagMax: '15', //标签上限
      tagLengthMax: '10', //标签长度上限
      choiceOptionNum: '2', //选择题选项下限
      maxOptionNum: '10', //填空上限
      fillOptionLengthMax: '50', //填空长度
      queansLengthMax: '9999', //答案长度
      difficultyNames: JSON.parse('["\u5176\u4ed6","\u7b80\u5355","\u666e\u901a","\u56f0\u96be"]'), //难度中文
      difficultClasses: ['simple', 'ordinary', 'difficulty'], //难度
      optionKeys: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'], //选项
      questionTopics: [], // 解析后所有题目
      doubleQuestionChooses: [], // 解析后重复所有选项
      doubleQuestionTopics: [], // 解析后重复所有题目
      doubleQuestionAnswers: [], // 解析后重复所有题目
      questionList: [],
      questionTemplate: [
        {
          questionType: 0,
          questionTypeName: '判断题',
          stem: '国际象棋起源于英国吗',
          answer: 'A',
          analysis: '无(若无解析本行可不填)',
          difficulty: '简单',
          expectedTime: '1分钟'
        },
        {
          questionType: 1,
          questionTypeName: '单选题',
          stem: '驾驶人有下列哪种违法行为一次记6分',
          options: ['使用其他车辆行驶证', '饮酒后驾驶机动车', '车速超过规定时速50%以上', '违法占用应急车道行驶'],
          answer: 'D',
          analysis: '请仔细阅读交规(若无解析本行可不填)',
          difficulty: '简单',
          expectedTime: '1分钟'
        },
        {
          questionType: 2,
          questionTypeName: '多选题',
          stem: '驾驶人有下列哪种违法行为一次记6分',
          options: ['使用其他车辆行驶证', '饮酒后驾驶机动车', '车速超过规定时速50%以上', '违法占用应急车道行驶'],
          answer: 'A,B,C,D',
          analysis: '请仔细阅读交规(若无解析本行可不填)',
          difficulty: '简单',
          expectedTime: '1分钟'
        },
        {
          questionType: 3,
          questionTypeName: '填空题',
          stem: '新中国成立日期__1__年__2__月__3__日',
          answer: '1949;一九四九|10;十|1;一',
          analysis: '无(若无解析本行可不填)',
          difficulty: '简单',
          expectedTime: '1分钟'
        },
        {
          questionType: 4,
          questionTypeName: '简答题',
          stem: '变配电室要求做到“五防一通”指的是什么?',
          answer: '五防:即防火、防水、防雷、防雪、防小动物。一通:即保持通风良好。',
          analysis: '无(若无解析本行可不填)',
          difficulty: '(若不填写自动设置为简单)',
          tags: ['防火|防水|防雷|防雪|防小动物|保持通风良好'],
          expectedTime: '(若不填写自动设置为1分钟)'
        },
        {
          questionType: 5,
          questionTypeName: '代码题',
          stem: '用代码实现加法',
          answer: '无',
          testcase: '1,1;2|1,2;3|1,3;4|1,5;6',
          analysis: '无(若无解析本行可不填)',
          difficulty: '(若不填写自动设置为简单)',
          expectedTime: '(若不填写自动设置为1分钟)'
        }
      ]
    }
  },
  methods: {
    downloadTemplate(type) {
      //下载模板
      fileApi.downloadQuestionTemplate(type).then(res => {
        this.$downloadFlow(res, '题目模板', type === 0 ? 'xlsx' : 'docx')
      })
    },
    openDropdown(key) {
      //显示范例/规范
      this.dropdownVisible = this.dropdownVisible === key ? '' : key
    },
    handleImportText(text) {
      this.inputEditor.txt.html(text)
    },
    initEditor() {
      this.inputEditor = new E('#inputEditor')
      this.inputEditor.config.menus = []
      this.inputEditor.config.familys = ['宋体', '黑体', '楷体', '微软雅黑']
      this.inputEditor.config.menuFixed = false
      this.inputEditor.config.showFullScreen = false
      // this.inputEditor.config.pasteFilterStyle = false
      this.inputEditor.config.pasteIgnoreImg = true
      this.inputEditor.create()
      this.inputEditorCopy = new E('#inputEditorCopy')
      this.inputEditorCopy.create()
    },
    formatText() {
      this.questionList = []
      this.questionTopics = []
      // 将编辑器中的换行替换成p标签, 再统一替换成\n
      let inputHtml = this.inputEditor.txt.html()
      inputHtml = inputHtml.replace(/<br.*?>/g, '</p>').split(/<\/p>/g)
      // console.log(inputHtml)
      const htmlRes = []
      inputHtml.forEach(item => {
        if (item !== '') {
          item = item.replace(/<p.*?>/, '')
          htmlRes.push(`${item}\n`)
        }
      })
      // console.log(htmlRes)
      this.inputEditorCopy.txt.html(htmlRes.join(''))

      // 1. 获取所有纯文本
      const inputText = this.inputEditorCopy.txt.text()
      const inputTexts = inputText.split('\n')
      // console.log(inputTexts)
      const inputTextRes = [] // 过滤掉空内容
      inputTexts.forEach(item => {
        if (item) {
          inputTextRes.push(this.sbcToHalf(item))
        }
      })

      // 2. 判断行，是否符合题目关键信息（题目、选项、答案、解析、难度）
      const lines = []
      // 最大索引
      let validLine = -1
      inputTextRes.forEach(item => {
        let newTxt = this.sbcToHalf(item).trim()
        validLine++
        lines.push(newTxt)
        // console.log(lines)
      })
      // 记录整合后行
      this.inputTextLine = lines
      this.inputTextLineCopy = lines

      // 3. 判断题目所在位置
      let questionIndex = []
      lines.forEach((item, index) => {
        // 判断是否为题目
        if (dialectBlock.ckTitle(item)) {
          questionIndex.push(index)
        }
      })

      // 4. 通过题目位置 进行考题切割
      let tmpQuestionArr = []
      let lineIndexArr = []
      questionIndex.forEach((item, index) => {
        lineIndexArr.push(item)
        let lineRes = lines.slice(item, questionIndex[index + 1])
        tmpQuestionArr.push(lineRes)
      })
      // console.log(tmpQuestionArr)
      // console.log(lineIndexArr)
      // 5. 遍历每个题，进行题型判断
      let tmpQuestionList = []
      tmpQuestionArr.forEach((lines, index) => {
        // 解析并提取内容
        let question = dialectBlock.parse(lines)
        // console.log('question', question)
        tmpQuestionList.push(question)
      })
      // console.log(tmpQuestionList)
      // 6. 判断是否符合规则，并计算对应 Index => ErrorArr(index)， QuestionList, Questions
      this.questionList = []
      tmpQuestionList.forEach(question => {
        let questionRes = this.checkQuestion(question)
        this.questionList.push(questionRes)
      })
      // console.log('questionList', this.questionList)

      // // 7. 二次校验
      // // 7.1 内容重复校验
      // questionList = this.checkDouble(questionList)
      // // 7.2 未识别题型校验
      // questionList = this.checkType(questionList)
      //
      // // 7. 封装数据(后台)，并计算错误所在行数
      // questionList.forEach(function(question, index) {
      //   // 添加不定项标识
      //   question['isIndeterminate'] = false
      //
      //   if (!question.hasError) {
      //     // 封装提交数据
      //     this.drawQuestion(question)
      //     //
      //     this.normalQuestions.push(question)
      //   } else {
      //     this.errorQuestions.push(question)
      //   }
      //   // 统计错误所在行数
      //   let lineIndex = lineIndexArr[index]
      //   // console .log('lineIndex', lineIndex)
      //   this.statErrors(question, lineIndex)
      //   this.statQuesErrors(question, index, lineIndex)
      // })
      //
      // // 8 对应有错误，则加入class=error ,否则普通p标签
      // this.markErrors()
      //
      // // 输出到检查区
      // this.questionList = questionList
      // this.parsing = false
      //
      // this.tmpQuestionsImages = {}
      // // 提示
      // this.showMaxTip(this.maxTipTxtMax)
    },
    sbcToHalf(str) {
      let result = ''
      for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) === 12288) {
          result += String.fromCharCode(str.charCodeAt(i) - 12256)
        }
        if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
          result += String.fromCharCode(str.charCodeAt(i) - 65248)
        } else {
          result += String.fromCharCode(str.charCodeAt(i))
        }
      }
      return result
    },
    // 校验考题规则
    checkQuestion(question) {
      return this.checkRule(question)
    },

    // 按题型检查
    checkRule(question, start) {
      start = start ? start : 0
      if (!question) return question
      let that = this
      // 错误
      let errors = {}
      // 题型
      let type = question.type
      // 题目
      let ques_title = question.title
      let title_all = that.htmlRestore(question.title_all)

      // let title_images = []
      // if (this.titleImgReg.test(title_all)) {
      //   let ms = title_all.match(this.titleImgReg)
      //   console.log('ms', ms)
      // if (ms.length > 0) {
      //   for (let i in ms) {
      //     let imgI = ms[i]
      //     console.log('imgI', imgI)
      //     title_all = title_all.replace(imgI, '')
      //     ques_title = ques_title.replace(imgI, '')
      //     title_images.push(this.tmpQuestionsImages[imgI])
      //   }
      // }
      // }

      let title_extra = question.title_extra
      let new_title_extra = []
      if (title_extra && title_extra.length > 0) {
        for (let i in title_extra) {
          let tmp = title_extra[i]

          // let ms = tmp.match(this.titleImgReg)
          //
          // for (let i in ms) {
          //   let imgI = ms[i]
          //   console.log('imgI', imgI)
          //   tmp = tmp.replace(imgI, '')
          // }
          if (tmp.length > 0) {
            new_title_extra.push(tmp)
          }
        }
      }

      question.title_extra = new_title_extra
      question.title = ques_title
      question.title_all = title_all
      // question.title_images = title_images

      let titleExtraKey = 'title_unp'
      let titleExtraLineKey = 'title_unp_line'
      if (question[titleExtraKey] && question[titleExtraKey].length > 0) {
        errors[titleExtraKey] = '题目存在未识别内容'
        errors[titleExtraLineKey] = question[titleExtraKey].length
      }

      // 答案
      let answer = that.htmlRestore(question.answer)
      question.answer = answer

      let answerExtraKey = 'answer_unp'
      let answerExtraLineKey = 'answer_unp_line'
      if (question[answerExtraKey] && question[answerExtraKey] && question[answerExtraKey].length > 0) {
        errors[answerExtraKey] = '答案存在未识别内容'
        errors[answerExtraLineKey] = question[answerExtraKey].length
      }

      // 选项
      let optionIndexs = question.optionIndexs
      let optionValues = question.optionValues
      let choose_all = Base64.encode(optionValues.join(''))

      // 解析
      let analysis = that.htmlRestore(that.checkUndefined(question.analysis))
      question.analysis = analysis

      let analysisExtraKey = 'analysis_unp'
      let analysisExtraLineKey = 'analysis_unp_line'
      if (question[analysisExtraKey] !== undefined && question[analysisExtraKey] && question[analysisExtraKey].length > 0) {
        errors[analysisExtraKey] = '分析存在未识别内容'
        errors[analysisExtraLineKey] = question[analysisExtraKey].length
      }

      // 难度
      let difficult = that.checkUndefined(question.difficult)

      let difficultExtraKey = 'difficult_unp'
      let difficultExtraLineKey = 'difficult_unp_line'
      if (question[difficultExtraKey] !== undefined && question[difficultExtraKey] && question[difficultExtraKey].length > 0) {
        errors[difficultExtraKey] = '难度存在未识别内容'
        errors[difficultExtraLineKey] = question[difficultExtraKey].length
      }

      // 长度限制
      if (title_all.length > that.stemDataLength) {
        errors.title = '题目过长'
      }
      if (
        that.questionTopics.indexOf(title_all) > -1 &&
        that.doubleQuestionChooses.indexOf(choose_all) > -1 &&
        that.doubleQuestionAnswers.includes(answer)
      ) {
        errors.title = '题目重复'
      }
      that.questionTopics.push(title_all)
      that.doubleQuestionChooses.push(choose_all)
      that.doubleQuestionAnswers.push(answer)
      question.choose_all = choose_all

      if (answer && answer.length > that.stemDataLength) {
        errors.answer = '答案过长'
      }
      if (analysis && analysis.length > that.answerAnalysisLength) {
        errors.analysis = '解析过长'
      }
      let ind = 0
      if (difficult.length > 0) {
        ind = this.ytConstant.difficultType.getValue(difficult)
        if (ind < 0) {
          errors.difficult = '难度错误，仅支持：简单、普通、困难'
        } else {
          question.difficultClass = that.difficultClasses[ind - 1]
          question.shortDifficult = difficult.substring(0, 1)
        }
      } else {
        difficult = '简单'
        question.difficultClass = that.difficultClasses[0]
        question.shortDifficult = difficult.substring(0, 1)
      }
      question.difficult = difficult
      question.difficultyValue = ind

      that.optionKeys.forEach(value => {
        let key = 'key' + value
        let extraKey = key + '_unp'
        let optionExtraLine = key + '_unp_line'
        let option = question[key]
        let optionExtra = question[extraKey]
        if (optionExtra && optionExtra.length > 0) {
          errors[extraKey] = '选项' + value + '存在未识别内容'
          errors[optionExtraLine] = question[extraKey].length
        }
        if (option && option.length > that.chooseLengthMax) {
          errors[key] = '选项过长'
        }
      })
      // 截取和答案匹配长度
      let tmpKeys = that.optionKeys.slice(0, optionIndexs.length)

      // 区分题型校验
      switch (type) {
        case 0: //判断题
          if (optionIndexs.length > 0) {
            if (optionIndexs.indexOf(answer) < 0) {
              errors.answer = '答案错误: 答案应在选项中'
            } else {
              if (optionIndexs.length !== 2) {
                errors.options = '选项错误: 和题型不符'
              }
            }
          }
          break
        case 1: //单选
          answer = answer.toUpperCase()
          let singleValues = optionValues.filter(s => {
            return s && s.trim()
          })
          if (singleValues.length !== optionValues.length) {
            errors.options = '选项内容错误：未填写'
          }
          let uniSingleValues = that.getUniqueArray(singleValues)
          if (uniSingleValues.length !== singleValues.length) {
            errors.options = '选项内容错误：有重复'
          }
          if (type === 1) {
            if (optionIndexs.length < that.choiceOptionNum || optionIndexs.length > that.maxOptionNum) {
              errors.options = '选项数量错误：最少2个，最多10个'
            }
          }
          // if (type == 3) {
          // 	if (optionIndexs.length > 0 && optionIndexs.length !== parseInt(that.judgeOptionNum)) {
          // 		errors.options = '选项错误：仅支持AB两个选项';
          // 	}
          // }

          // 答案
          if (optionIndexs.indexOf(answer) < 0) {
            errors.answer = '答案错误: 答案应在选项中'
          }
          // console.log('tmpKeys', tmpKeys, optionIndexs)
          tmpKeys.forEach(value => {
            let tmpV = value.toLocaleUpperCase()
            if (optionIndexs.indexOf(tmpV) < 0) {
              errors.options = '选项标识错误：应为A-J'
            }
          })
          break
        case 2:
          answer = answer.toUpperCase()
          let doubleValues = optionValues.filter(s => {
            return s && s.trim()
          })
          if (doubleValues.length !== optionValues.length) {
            errors.options = '选项内容错误：未填写'
          }
          let uniDoubleValues = that.getUniqueArray(doubleValues)
          if (uniDoubleValues.length !== doubleValues.length) {
            errors.options = '选项内容错误：有重复'
          }

          if (optionIndexs.length < that.choiceOptionNum || optionIndexs.length > that.maxOptionNum) {
            errors.options = '选项数量错误：最少2个，最多10个'
          }
          if (answer.length < 2) {
            errors.answer = '答案错误：至少2个正确答案'
          }
          tmpKeys.forEach(value => {
            let tmpV = value.toLocaleUpperCase()
            if (optionIndexs.indexOf(tmpV) < 0) {
              errors.options = '选项标识错误：应为A-J'
            }
          })

          if (answer.match(/^([a-j][,|，.]){0,7}([a-i])$/gi)) {
            answer = answer.replace(/[,，.]/g, '')
          }
          let tmps = answer.split('')
          tmps.forEach((value, index) => {
            if (optionIndexs.indexOf(value) < 0) {
              errors.answer = '答案错误：答案应在选项中'
            }
          })

          break
        case 3:
          if (optionValues.length > 0 || optionIndexs.length > 0) {
            errors.options = '选项错误：不应有选项'
          }
          //
          let tmpArr = answer.split('|')
          if (tmpArr.length < 0) {
            errors.answer = '答案错误：不符合格式'
          }
          if (tmpArr.length > that.maxOptionNum) {
            errors.answer = '答案错误：超过限制10个空'
          }
          let allVals = []
          let noEmptyArr = []
          tmpArr.forEach(value => {
            if (value || value !== '') {
              noEmptyArr.push(value)
            }
          })
          noEmptyArr.forEach(value => {
            if (value.indexOf(';') > -1) {
              let tmp = value.split(';')
              tmp.forEach(val => {
                if (val.length > that.fillOptionLengthMax) {
                  errors.answer = '答案错误：超过长度限制'
                }
                allVals.push(val)
              })
            } else {
              if (value.length > that.fillOptionLengthMax) {
                errors.answer = '答案错误：超过长度限制'
              }
              allVals.push(value)
            }
          })

          let newAllVals = that.getUniqueArray(allVals)
          //2020-9-12 v1.7.3.1填空题选项允许重复
          /* 						if (newAllVals.length !== allVals.length) {
                        errors.answer = '答案错误：存在重复';
                      } */
          // 判断空和答案数量
          let total = dialectBlock.getBracketsCount(title_all)
          if (total <= 0) {
            errors.title = '题目错误：没有预留__序号__'
          }
          if (total !== noEmptyArr.length) {
            errors.answer = '题目错误：答案和填空数量不符'
          }
          break
        case 4:
          break
        case 5:
          if (answer.length > that.queansLengthMax) {
            errors.answer = '答案错误：超过长度限制'
          }
          break
        default:
          errors.question = '考题错误：未识别'
      }

      // 标签
      let kword = that.checkUndefined(question.kword)
      // console.log('type', type, kword)
      if (kword) {
        let kwordArr = kword.split('|')
        // console.log('kwordArr', kwordArr)
        if (kwordArr.length > that.tagMax) {
          errors['kword'] = '标签数量超过限制'
        }
        for (let k in kwordArr) {
          if (kwordArr[k].length > that.tagLengthMax) {
            errors['kword'] = '第' + (+k + 1) + '个标签长度超过限制'
            break
          }
          // 内容校验
          if (this.isCheckFormat(kwordArr[k])) {
            errors['kword'] = '第' + (+k + 1) + '个标签选项错误'
            break
          }
        }

        // console.log('errors', errors)
        let kwordExtraKey = 'kword_unp'
        let kwordExtraLineKey = 'kword_unp_line'
        if (question[kwordExtraKey].length > 0) {
          errors[kwordExtraKey] = '关键词存在未识别内容'
          errors[kwordExtraLineKey] = question[kwordExtraLineKey].length
        }

        question.kwordValue = kwordArr
      }
      //预期时间
      let expectedTime = question.expectedTime
      if (expectedTime.split('分钟')[0] % 1 !== 0) {
        errors.expectedTime = '预期时间应该为整数'
      } else if (expectedTime.split('分钟')[0] < 1) {
        errors.expectedTime = '预期时间小于1分钟'
      } else if (expectedTime.split('分钟')[0] > 60) {
        errors.expectedTime = '预期时间大于60分钟'
      }
      // 解析正确答案
      let rightAnswer = ''
      if (!errors.answer && !errors.options) {
        rightAnswer = that.parseAnswer(type, answer, optionIndexs)
      }
      // console .log('errors', errors)

      question.hasError = Object.keys(errors).length > 0
      question.rightAnswer = rightAnswer
      question.errors = errors
      //
      return question
    },
    // 处理 undefined
    checkUndefined(txt) {
      return txt ? (txt.indexOf('undefined') > -1 ? '' : txt) : ''
    },
    // 获取唯一数组
    getUniqueArray(answers) {
      if (answers.length < 1) {
        return []
      }
      return answers.filter((element, index, self) => {
        return self.indexOf(element) === index
      })
    },
    htmlRestore(str) {
      let s = ''
      if (!str || str.length === 0) {
        return ''
      }
      s = str.replace(/&amp;/g, '&')
      s = s.replace(/&lt;/g, '<')
      s = s.replace(/&gt;/g, '>')
      s = s.replace(/&nbsp;/g, ' ')
      s = s.replace(/&#39;/g, "'")
      s = s.replace(/&quot;/g, '"')
      return s
    },
    // 获取检查区正确答案
    parseAnswer(qt_type, answerValue) {
      let that = this
      switch (qt_type) {
        // 单选
        case 1:
          return 'key' + answerValue
        // 多选
        case 6:
        case 2:
          let tmpValues = answerValue.split('')
          let tmpAnswer = []
          tmpValues.forEach(item => {
            tmpAnswer.push('key' + item)
          })
          return tmpAnswer
        // 判断
        case 3:
          if (answerValue.match(that.rightReg)) {
            return 'keyA'
          }
          if (answerValue.match(that.wrongReg)) {
            return 'keyB'
          }
          return ''
        case 4:
          return answerValue
        case 5:
          return answerValue
      }
      return ''
    },
    // 关键字正则格式校验
    isCheckFormat(str) {
      let reg = /[^a-zA-Z0-9\u4E00-\u9FA5`~!@#$%^&*()_+<>?:"{},.\/;'[\]\-·！￥（—）：；“”‘、，|《。》？【】+-×÷m²³=‰\s]/
      return reg.test(str) || this.isEmojiCharacter(str)
    },
    //判断是否含有emoji表情
    isEmojiCharacter(substring) {
      for (let i = 0; i < substring.length; i++) {
        let hs = substring.charCodeAt(i)
        if (0xd800 <= hs && hs <= 0xdbff) {
          if (substring.length > 1) {
            let ls = substring.charCodeAt(i + 1)
            let uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000
            if (0x1d000 <= uc && uc <= 0x1f77f) {
              return true
            }
          }
        } else if (substring.length > 1) {
          let ls = substring.charCodeAt(i + 1)
          if (ls === 0x20e3) {
            return true
          }
        } else {
          if (0x2100 <= hs && hs <= 0x27ff) {
            return true
          } else if (0x2b05 <= hs && hs <= 0x2b07) {
            return true
          } else if (0x2934 <= hs && hs <= 0x2935) {
            return true
          } else if (0x3297 <= hs && hs <= 0x3299) {
            return true
          } else if (
            hs === 0xa9 ||
            hs === 0xae ||
            hs === 0x303d ||
            hs === 0x3030 ||
            hs === 0x2b55 ||
            hs === 0x2b1c ||
            hs === 0x2b1b ||
            hs === 0x2b50
          ) {
            return true
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.import-wrapper {
  .flexStyle(flex, space-between);
  margin: 0 10px;
  position: relative;
  .textarea-wrapper {
    width: 443px;
  }
  .download {
    display: none;
    width: 1080px;
    height: 0;
    background: #f8f9fb;
    border-radius: 10px 10px 0 0;
    position: absolute;
    bottom: 0;
    left: -60px;
    z-index: 99999;
    &.active {
      .flexStyle(flex);
      height: 380px;
    }
    .item {
      width: 460px;
      height: 320px;
      background: #ffffff;
      border-radius: 8px;
      .header {
        .flexStyle(flex, space-between);
        height: 60px;
        border-radius: 8px 8px 0 0;
        color: #ffffff;
        padding: 0 20px;
        span {
          font-size: @default;
          font-weight: bold;
        }
        div {
          width: 90px;
          height: 32px;
          line-height: 30px;
          border: 1px solid #ffffff;
          border-radius: 16px;
          text-align: center;
          cursor: pointer;
        }
      }
      .body {
        padding: 20px;
        color: #000;
        h4 {
          font-size: @default;
          margin-bottom: 12px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.textarea-wrapper {
  position: relative;
  .header {
    div {
      float: right;
      margin-left: 11px;
      cursor: pointer;
      color: #448bff;
      svg {
        margin-left: 2px;
        transition: all 0.2s ease-in-out;
        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }
  .textarea {
    width: 443px;
    height: calc(100vh - 150px - 225px);
    margin-top: 6px;
    margin-bottom: 31px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    ::v-deep .w-e-toolbar {
      border: none !important;
    }
    ::v-deep .w-e-text-container {
      border: none !important;
      height: 100% !important;
      border-radius: 4px;
      padding: 16px;
      .w-e-text {
        height: 100% !important;
        min-height: 401px !important;
        padding: 0;
        p {
          margin: 0;
        }
      }
      .placeholder {
        top: 16px;
        left: 16px;
      }
    }
  }
  .output {
    padding: 16px 15px 16px 5px;
    background-color: #fcfcfc;
  }
  .standard {
    height: calc(100vh - 150px - 225px);
    padding: 10px 15px 15px;
    background-color: #ffffff;
    position: absolute;
    top: 21px;
    h4 {
      color: #448bff;
      text-align: center;
      margin-bottom: 25px;
    }
    p {
      margin-bottom: 12px;
    }
  }
  .example {
    overflow-y: auto;
    background: #edf4ff;
    p {
      margin-bottom: 10px;
    }
    div {
      &:last-of-type {
        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .question-type {
    width: 47px;
    padding: 1px 6px;
    background: #448bff;
    border-radius: 4px;
    color: #ffffff;
    margin: 0 6px;
  }
  .question-item {
    padding: 10px;
    margin-bottom: 10px;
    p {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &.error {
      background-color: rgba(255, 80, 80, 0.1);
      border: 1px solid rgba(255, 80, 80, 1);
      border-radius: 4px;
    }
  }
}
.preview {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #448bff;
  box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
  border-radius: 20px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.is-fullscreen {
  .textarea-wrapper {
    width: 100%;
  }
  .preview {
    flex-shrink: 0;
    margin: 0 20px;
  }
  .textarea {
    width: 100%;
    height: calc(100vh - 311px);
  }
}
@media screen and (max-height: 768px) {
  .textarea-wrapper {
    .textarea {
      ::v-deep .w-e-text-container {
        .w-e-text {
          min-height: unset !important;
        }
      }
    }
  }
  .standard {
    overflow-y: auto;
  }
}
</style>
