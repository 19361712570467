<template>
  <div>
    <el-form-item label="答案1">
      <div class="option-wrapper" :class="{ error: errorList.hasOwnProperty('answer') && errorList.answer }">
        <el-input style="width: calc(100% - 170px)" disabled value="正确"></el-input>
        <el-radio v-model="form.answer" @change="handleChange" class="set-correct-choice" :label="true" border>正确答案</el-radio>
      </div>
    </el-form-item>
    <el-form-item label="答案2" prop="answer">
      <div class="option-wrapper" :class="{ error: errorList.hasOwnProperty('answer') && errorList.answer }">
        <el-input style="width: calc(100% - 170px)" disabled value="错误"></el-input>
        <el-radio v-model="form.answer" @change="handleChange" class="set-correct-choice" :label="false" border>正确答案</el-radio>
      </div>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'TrueOrFalse',
  props: ['form', 'errorList'],
  methods: {
    handleChange() {
      this.$emit('validate', 'answer')
    }
  }
}
</script>

<style scoped lang="less">
.el-input.is-disabled {
  ::v-deep .el-input__inner {
    height: 38px;
    border-color: #d9d9d9;
    color: #666666;
    background-color: #ffffff;
  }
}
.error {
  ::v-deep .el-input__inner {
    border-color: #ff5050 !important;
  }
  ::v-deep .is-bordered {
    border-color: #ff5050;
  }
}
</style>
